import { createStyles } from "@material-ui/core";

export const styles = () =>
  createStyles({
    container: {
      overflow: "hidden",
      zIndex: -1,
    },
    greenDots: {
      width: "160px",
      height: "710px",
    },
    greenDotsHorizontal: {
      height: "160px",
      width: "710px",
    },
  });
