import { WithStyles, withStyles } from "@material-ui/core";

import GreenDotsSvg from "@src/assets/icons/GreenDots.svg";
import GreenDotsHorizontalSvg from "@src/assets/icons/GreenDotsHorizontal.svg";
import cn from "classnames";
import React from "react";

import { styles } from "./GreenDots.styles";

interface AboutTemplateProps extends WithStyles<typeof styles> {
  className?: string;
  horizontal?: boolean;
}

class GreenDots extends React.PureComponent<AboutTemplateProps> {
  public render() {
    const { classes, className, horizontal } = this.props;

    return (
      <div className={cn(className, classes.container)}>
        {horizontal ? (
          <GreenDotsHorizontalSvg className={classes.greenDotsHorizontal} />
        ) : (
          <GreenDotsSvg className={classes.greenDots} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(GreenDots);
